<template>
  <div class="home">
    <section
      class="
        bg-black
        text-white
        h-screen
        w-screen
        flex
        items-center
        justify-center
      "
    >
      <div class="text-center">
        <h1 class="text-7xl font-extrabold tracking-widest mb-4">MV1</h1>
        <h2 class="tracking-widest mb-8 font-bold">metaverse 1</h2>
        <h3 class="mb-8">for everyone &amp; everything</h3>
        <p class="text-xs">
          <a href="https://fridaycollective.co.uk" target="_blank" class=""
            >by fridaycollective</a
          >
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
